import React, { useEffect } from 'react'
import Seo from './../components/seo/seo'
import Layout from './../components/layout/Layout'

const EstimateProjects = () => {
  useEffect(() => {
    setTimeout(() => {
      window.$(function () {
        window.$('input').change(function () {
          if (window.$('#name').val() != '' && window.$('#email').val() != '') {
            window
              .$('#estimate-projects-form-submit-btn')
              .removeAttr('disabled')
          } else {
            window
              .$('#estimate-projects-form-submit-btn')
              .attr('disabled', 'disabled')
          }
        })
      })

      window.$("input[type='file']").on('change', function () {
        if (this.files && this.files[0].size > 5242880) {
          window
            .Toastify({
              text: 'Please upload file less than 5MB. Thanks!!',
              duration: 5000,
              newWindow: true,
              gravity: 'bottom', // `top` or `bottom`
              position: 'center', // `left`, `center` or `right`
              stopOnFocus: true, // Prevents dismissing of toast on hover
              style: {
                background: 'linear-gradient(to right, #D7816A, #BD4F6C)',
              },
            })
            .showToast()
          window.$('#job-open-form-submit-btn').attr('disabled', 'disabled')
          window.$('#fileUpload').val('')
          const btnTextChange = document.querySelector('#fileTextChange')
          btnTextChange.innerHTML = `<i class="fi-rr-cloud-upload me-2 ms-0"></i> Upload a file`
        }
      })

      // call email api
      window.$(document).ready(function () {
        window.$('#estimate-projects-form').on('submit', function (e) {
          e.preventDefault()
          window.$('#estimate-projects-form-submit-btn').text('Please wait..')
          window.$('#estimate-projects-form-submit-btn').prop('disabled', true)

          var formdata = new FormData(this)

          window.$.ajax({
            url: 'https://api.immence.com/email/estimate-projects',
            type: 'POST',
            cache: false,
            data: formdata,
            contentType: false,
            processData: false,
            success: function (response) {
              window
                .Toastify({
                  text: 'Thank you for contacting us. We will get back to you soon.',
                  duration: 5000,
                  newWindow: true,
                  gravity: 'bottom', // `top` or `bottom`
                  position: 'center', // `left`, `center` or `right`
                  stopOnFocus: true, // Prevents dismissing of toast on hover
                  style: {
                    background: 'linear-gradient(to right, #63A4FF, #0BAB64)',
                  },
                })
                .showToast()

              window
                .$('#estimate-projects-form-submit-btn')
                .text('Send Message')
              window
                .$('#estimate-projects-form-submit-btn')
                .prop('disabled', true)
              window.$('#estimate-projects-form').trigger('reset')
              window.$('#fileUpload').val('')
              const btnTextChange = document.querySelector('#fileTextChange')
              btnTextChange.innerHTML = `<i class="fi-rr-cloud-upload me-2 ms-0"></i> Upload a file`
            },
            error: function (response) {
              window
                .Toastify({
                  text: 'Something went wrong. Please try again later.',
                  duration: 5000,
                  newWindow: true,
                  gravity: 'bottom', // `top` or `bottom`
                  position: 'center', // `left`, `center` or `right`
                  stopOnFocus: true, // Prevents dismissing of toast on hover
                  style: {
                    background: 'linear-gradient(to right, #D7816A, #BD4F6C)',
                  },
                })
                .showToast()

              window
                .$('#estimate-projects-form-submit-btn')
                .text('Send Message')
              window
                .$('#estimate-projects-form-submit-btn')
                .prop('disabled', false)
            },
          })
        })
      })
    }, 1000)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      window.AOS.init({
        duration: 1200,
        easing: 'ease-out-back',
      })
    }, 1000)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const fileUpload = document.querySelector('#fileUpload')
      const btnTextChange = document.querySelector('#fileTextChange')

      fileUpload.addEventListener('change', (e) => {
        console.log(fileUpload.files)
        btnTextChange.innerHTML = `${fileUpload.files[0].name}`
      })
      // window.Dropzone.myGreatDropzone = {
      //     paramName: "file", // The name that will be used to transfer the file
      //     maxFilesize: 2, // MB
      //     dictDefaultMessage:
      //         "<strong>Drop files here or click to upload. </strong></br> (This is just a demo dropzone. Selected files are not actually uploaded.)",
      // };
    }, 1000)
  }, [])

  return (
    <>
      <Seo
        title={'Let’s talk business | immence'}
        description={
          'Share a little bit about what you’re building and an expert on our end will reach out to start a conversation.'
        }
      />
      <Layout>
        <section className="com-section estimation-project">
          <div className="container">
            <div className="row paddr15">
              <div className="col-lg-5 aos-init aos-animate" data-aos="fade-up">
                <div className="paddr15">
                  <h2 className="hero-heading">
                    Let’s talk
                    <span className="blue-span">business!</span>
                  </h2>
                  <p>
                    Describe your challenge, and we’ll get back to you to
                    discuss how to future-proof your business.
                  </p>
                </div>
                <div className="mt-4 post-img respost_img">
                  <img
                    src={require('../img/start_a_project.svg').default}
                    alt="start_a_project"
                  />
                </div>
              </div>
              <div
                className="col-lg-7 contact-form-box aos-init aos-animate"
                data-aos="fade-up"
              >
                <form id="estimate-projects-form">
                  <p className="sub-title">Pick what you are interested in</p>
                  <div className="form-wrap form-group">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="project-type"
                        id="project-type-1"
                        defaultValue="Custom Software Development"
                      />
                      <label
                        className="form-check-label d-flex animation-icon"
                        htmlFor="project-type-1"
                      >
                        <img
                          src={
                            require('../img/project_interest/custom.svg')
                              .default
                          }
                          alt="custom_icon"
                          style={{ width: 25, height: 30, marginRight: 5 }}
                        />
                        Custom Software Development
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="project-type"
                        id="project-type-2"
                        defaultValue="Solution Design"
                      />
                      <label
                        className="form-check-label d-flex animation-icon"
                        htmlFor="project-type-2"
                      >
                        <img
                          src={
                            require('../img/project_interest/solution.svg')
                              .default
                          }
                          alt="solution_icon"
                          style={{ width: 25, height: 30, marginRight: 5 }}
                        />
                        Solution Design
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="project-type"
                        id="project-type-3"
                        defaultValue="Web Development"
                      />
                      <label
                        className="form-check-label d-flex animation-icon"
                        htmlFor="project-type-3"
                      >
                        <img
                          src={
                            require('../img/project_interest/web.svg').default
                          }
                          alt="web_icon"
                          style={{ width: 25, height: 30, marginRight: 5 }}
                        />
                        Web Development
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="project-type"
                        id="project-type-4"
                        defaultValue="Mobile App Development"
                        defaultChecked
                      />
                      <label
                        className="form-check-label d-flex animation-icon"
                        htmlFor="project-type-4"
                      >
                        <img
                          src={
                            require('../img/project_interest/mobile.svg')
                              .default
                          }
                          alt="mobile_icon"
                          style={{ width: 25, height: 30, marginRight: 5 }}
                        />
                        Mobile App Development
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="project-type"
                        id="project-type-5"
                        defaultValue="Game Development"
                      />
                      <label
                        className="form-check-label d-flex animation-icon"
                        htmlFor="project-type-5"
                      >
                        <img
                          src={
                            require('../img/project_interest/game.svg').default
                          }
                          alt="game_icon"
                          style={{ width: 25, height: 30, marginRight: 5 }}
                        />
                        Game Development
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="project-type"
                        id="project-type-6"
                        defaultValue="UI/UX Design"
                      />
                      <label
                        className="form-check-label d-flex animation-icon"
                        htmlFor="project-type-6"
                      >
                        <img
                          src={
                            require('../img/project_interest/design.svg')
                              .default
                          }
                          alt="design_icon"
                          style={{ width: 25, height: 30, marginRight: 5 }}
                        />
                        UI/UX Design
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="project-type"
                        id="project-type-7"
                        defaultValue="DevOps & Cloud"
                      />
                      <label
                        className="form-check-label d-flex animation-icon"
                        htmlFor="project-type-7"
                      >
                        <img
                          src={
                            require('../img/project_interest/devops.svg')
                              .default
                          }
                          alt="devops_icon"
                          style={{ width: 25, height: 30, marginRight: 5 }}
                        />
                        DevOps &amp; Cloud
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-4 col-md-6 form-group">
                      <label htmlFor="name" className="form-label required">
                        Your name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Type your name"
                        required
                      />
                    </div>
                    <div className="mb-4 col-md-6 form-group">
                      <label htmlFor="email" className="form-label required">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        aria-describedby="emailHelp"
                        placeholder="Type your email"
                        required
                      />
                    </div>
                    <div className="mb-4 col-md-6 form-group">
                      <label htmlFor="message" className="form-label">
                        About your project
                      </label>
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        placeholder="Tell us about your project"
                        defaultValue={''}
                      />
                    </div>
                    <div className="mb-4 col-md-6">
                      <label
                        className="form-label"
                        htmlFor="estimate_project_document"
                      >
                        Attach your file
                      </label>
                      <div className="dropzone dropdown-setting">
                        <div className="upload-btn-wrapper estimate-wrapper mb-4">
                          <div className="d-inline position-relative">
                            <input
                              className="form-control"
                              id="fileUpload"
                              name="file"
                              type="file"
                            />
                            <button
                              className="btn btn-outline-light btn-ouline-sm"
                              id="fileTextChange"
                            >
                              <i className="fas fa-cloud-upload" />
                              Drop files here to upload
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="sub-title">Project budget (USD)</p>
                  <div className="form-wrap usd-wrap ">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="project-budget"
                        id="project-budget-6"
                        defaultValue="Less than 5k USD"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="project-budget-6"
                      >
                        {' '}
                        &lt; 5k{' '}
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="project-budget"
                        id="project-budget-7"
                        defaultValue="Between 5k and 10k USD"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="project-budget-7"
                      >
                        5k - 10k
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="project-budget"
                        id="project-budget-8"
                        defaultValue="Between 10k to 20k USD"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="project-budget-8"
                      >
                        10 - 20k
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="project-budget"
                        id="project-budget-9"
                        defaultValue="Between 20k to 50k USD"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="project-budget-9"
                      >
                        20 - 50k
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="project-budget"
                        id="project-budget-10"
                        defaultValue="More than 50k USD"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="project-budget-10"
                      >
                        &gt; 50k
                      </label>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-outline-light-fill btn-ouline"
                    id="estimate-projects-form-submit-btn"
                    disabled="disabled"
                  >
                    Send request
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default EstimateProjects
